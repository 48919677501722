import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    window: String,
  };


  // When you want to toggle single element use id
  //  <div data-report-abuse-selector >Selector</div>
  // <div data-controller='activate' data-selector='#some-id' data-action='click->activate#toggleHidden'>

  // When you want to select multiple elements use data
  // <div data-controller='activate' data-selector="[data-report-abuse-selector]" data-action='click->activate#toggleHidden'>
  toggleHidden(event) {
    event.preventDefault();
    document
      .querySelectorAll(event.currentTarget.dataset.selector)
      .forEach((element) => element.classList.toggle("hidden"));
  }
  //usage: one mutlipleToggle per page
  //  <a
  //   href="#"
  //   data-controller='activate'
  //   data-activate-window-value='#window-1'
  //   data-action="click->activate#activateWindow"
  //   class="btn active"
  //   >
  //   Tab 1
  // </a>
  // <a
  //   href="#"
  //   data-controller='activate'
  //   data-activate-window-value='#window-2'
  //   data-action="click->activate#activateWindow"
  //   class="btn"
  //   >
  //   Tab 2
  // </a>
  // <div class='text-center'
  //               id='window-1'
  //               data-all-others-windows
  //             >Window 1</div>
  // <div class='text-center hidden'
  //               id='window-2'
  //                 data-all-others-windows
  //             >Window 2</div>
  activateWindow(event) {
    event.preventDefault();

    document.querySelectorAll(
      "[data-all-others-windows]"
    ).forEach((element) => element.classList.add("hidden"));
  

    document.querySelectorAll(
      '[data-action="click->activate#activateWindow"]'
    ).forEach((element) => {element.classList.remove("active")})


    document.querySelectorAll(this.windowValue).forEach((element) => {
      element.classList.remove("hidden");
    });
   
    this.element.classList.add("active");

  }

  scrollHalfwayPoint(event) {
    const halfwayPoint = document.documentElement.scrollHeight;
    window.scrollTo({ top: halfwayPoint, behavior: "smooth" });
  }

  addDNone(e) {
    this.element.classList.add("d-none");
  }
}
