// Importing the necessary dependencies from Stimulus and Cropper.js
import { Controller } from "stimulus";
import Cropper from "cropperjs";

// Define the Stimulus controller and its targets
// `cropperSource` is the image that will be cropped
// `cropperOutput` is the cropped image (not used directly in this example)
// `cropperInput` is the file input for selecting the image
// `cropperBtn` contains the buttons for cropping and canceling
// `cropperSkipButton` is an optional button to skip cropping

 // Example HTML structure explanation:
  // <div data-controller="cropper">
  //   <!-- Image container where the cropper will be initialized -->
  //   <div class="image-container">
  //     <img src="" alt="Selected Image" id="cropperSource" 
  //          data-cropper-target="cropperSource" 
  //          class="d-none">
  //   </div>
  //
  //   <!-- File input to select the image -->
  //   <div class="my-4">
  //     <input type="file" accept="image/*" 
  //            data-action="change->cropper#handleFileSelect" 
  //            class="form-control" 
  //            data-cropper-target="cropperInput">
  //   </div>
  //
  //   <!-- Buttons for cropping and canceling, initially hidden -->
  //   <div class="d-none" data-cropper-target="cropperBtn">
  //     <button type="button" class="btn btn-primary" 
  //             data-action="click->cropper#click">
  //       Crop and Upload
  //     </button>
  //     <button type="button" class="btn btn-secondary" 
  //             data-action="click->cropper#cancel">
  //       Cancel
  //     </button>
  //   </div>
  //
  //   <!-- Optional: Skip cropping and proceed -->
  //   <div class="my-4">
  //     <button type="button" class="btn btn-light" 
  //             data-cropper-target="cropperSkipButton">
  //       Skip Cropping
  //     </button>
  //   </div>
  // </div>

// 1. When the user selects a file using the file input, `handleFileSelect()` will be triggered,
//    loading the image into the `cropperSource` target and initializing the cropper.
// 2. The "Crop and Set as Profile" button will send the cropped image to the server.
// 3. The "Cancel" button resets the cropper and file input, allowing the user to start over.

// Constants to define the dimensions of the cropped image. These values set the width and height 
// of the final cropped image in pixels. They ensure that the cropped output will always match the 
// desired aspect ratio and dimensions, regardless of the original image size.
const CROPPED_IMAGE_WIDTH = 300;
const CROPPED_IMAGE_HEIGHT = 350;

export default class extends Controller {
  static targets = [
    "cropperSource", // The image element where Cropper.js will be initialized
    "cropperOutput", // Placeholder for the cropped image (optional use)
    "cropperInput",  // The file input field to select the image
    "cropperInputHeading",
    "cropperUploadBtn", // Upload Button
    "cropperBtn",    // Buttons to trigger crop and cancel
    "cropperSkipButton" // Button to skip the cropping process
  ];

  // Called automatically when the controller is connected to the DOM
  connect() {
    this.initCropper(); // Initialize Cropper.js when the controller is loaded
  }

  // Handle the file selection event when a user selects an image
  // The event parameter contains the file input event
  handleFileSelect(event) {
    const file = event.target.files[0]; // Get the selected file

    if (file) {
      const reader = new FileReader(); // Create a FileReader to read the image file
      reader.onload = (e) => {
        const imageUrl = e.target.result; // Get the image as a data URL

        const imageElement = this.cropperSourceTarget; // Access the image element target
        imageElement.src = imageUrl; // Set the image source to the selected file

        this.initCropper(); // Initialize or re-initialize Cropper.js with the new image
        this.cropperBtnTarget.classList.remove('d-none'); // Show the cropper buttons
        this.cropperUploadBtnTarget.classList.add('d-none'); // Hide Upload Button
        this.cropperInputTarget.classList.add('d-none'); // Hide the file input after selection
        if (this.hasCropperInputHeadingTarget) {
          this.cropperInputHeadingTarget.classList.add('d-none'); // Hide the input heading
        }

        this.cropperSkipButtonTarget.classList.add('d-none'); // Hide the skip button
      };
      reader.readAsDataURL(file); // Read the image file as a Data URL
    }
  }

  // Initialize or re-initialize the Cropper.js instance on the image
  initCropper() {
    if (this.cropper) {
      this.cropper.destroy(); // If a cropper already exists, destroy it to avoid multiple instances
    }

    // Create a new Cropper.js instance with options for aspect ratio and container size
    this.cropper = new Cropper(this.cropperSourceTarget, {
      aspectRatio: CROPPED_IMAGE_WIDTH / CROPPED_IMAGE_HEIGHT, // Set the desired aspect ratio
      viewMode: 1,
      dragMode: 'move', // Allow the image to be dragged around inside the cropping box
      minContainerWidth: CROPPED_IMAGE_WIDTH, // Set the minimum width of the cropper's container to match the cropped image width. This ensures a consistent UI size.
      minContainerHeight: CROPPED_IMAGE_HEIGHT, // Set the minimum height of the cropper's container to match the cropped image height, ensuring the UI is proportionate.
    });
  }

  // Triggered when the "Crop and Set as Profile" button is clicked
  click() {
    const button = document.querySelector("#CropAndSetAsProfile");
    if (button) {
      button.disabled = true;
      button.innerText = "Processing...";
    }

    const croppedCanvas = this.cropper.getCroppedCanvas();
    const croppedImage = croppedCanvas.toDataURL("image/jpeg"); // Convert the cropped canvas to a JPEG Data URL

    this.postToAPI(croppedImage); // Send the cropped image to the server
  }

  // Asynchronously send the cropped image to the server
  async postToAPI(croppedData) {
    try {
      const photo_id = this.data.get("memberPictureId"); // Get the ID of the member picture from the data attribute
      const dataURL = croppedData; // The cropped image in Data URL format

      // Send the cropped image data to the server using a POST request
      const response = await fetch(`/profile/crop_image/?member_picture=${photo_id}`, {
        method: "POST", // Use POST method to send data
        headers: {
          "Content-Type": "application/json", // Set content type as JSON
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content, // Include CSRF token for security
        },
        body: JSON.stringify({
          photo: {
            member_picture: photo_id, // The ID of the member picture
            cropped_image: dataURL, // The cropped image data
          },
        }),
      });

      const result = await response.json(); // Parse the JSON response
      if (response.ok) { // If the response is successful
        if (result.notice) {
          console.log(result.notice); // Log the notice message
          window.location.reload(true); // Reload the page to reflect changes
        }
      } else {
        // Handle error case where response is not ok
        if (result.error) {
          console.error('Error:', result.error); // Log errors if the request fails
          alert(result.error); // Show alert with the error message to the user
        }
      }
    } catch (error) {
      console.error('Error:', error); // Catch and log any errors
    }
  }

  // Called when the "Cancel" button is clicked to reset the cropper
  cancel() {
    // Destroy the current cropper instance if it exists
    if (this.cropper) {
      this.cropper.destroy();
      this.cropper = null; // Set the cropper reference to null
    }

    this.cropperInputTarget.value = ''; // Clear the file input value

    // Reset the image preview and hide the cropper buttons
    this.cropperSourceTarget.src = ''; // Clear the image source
    this.cropperBtnTarget.classList.add('d-none'); // Hide the cropper buttons
    this.cropperUploadBtnTarget.classList.remove('d-none');
    this.cropperInputTarget.classList.remove('d-none'); // Show the file input again
    this.cropperSkipButtonTarget.classList.remove('d-none'); // Show the skip button again
    
    if (this.hasCropperInputHeadingTarget) {
      this.cropperInputHeadingTarget.classList.remove('d-none'); /// Show the input heading again
    }
  }

  openFileDialog() {
    this.cropperInputTarget.click();
  }
}
