// app/javascript/controllers/form-validation_controller.js
import { Controller } from "stimulus"

// form validation enables submit button when input field is populated with any value (not null or empty string).    
// you can add more targets,like checkbox, radio button, etc
// <%= f.text_field :email,"data-form-validation-target": "input" , "data-action": "input->form-validation#validate" %>
// <%= f.submit "search", "data-form-validation-target":"submitButton" %>
export default class extends Controller {

  static targets = ["input", "submitButton"]

  connect() {
      this.checkValidity()
  }

  validate() {
    console.log("validate")
    this.checkValidity()
  }

  checkValidity() {
    const requiredInputs = this.inputTargets.filter(
      (input) => !input.dataset.optional
    );
  
    const inputsValid = requiredInputs.every(input => input.value.trim() !== "");
  
    const fileInputElement = this.element.querySelector('[data-file-input]');
    const fileInputValid = fileInputElement ? fileInputElement.files.length > 0 : true;
  
    const checkboxElement = this.element.querySelector('[data-checkbox]');
    const checkboxValid = checkboxElement ? checkboxElement.checked : true;
  
    this.submitButtonTarget.disabled = !(inputsValid && checkboxValid && fileInputValid);
  }
  
}
